//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SizeTable from "@/components/popups/SizeTable/Index.vue";
import {mapGetters} from "vuex";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import bellIcon from "@/assets/img/icons/bell-icon.svg";

export default {
    name: "SizeElement",
    data: function() {
        return {
            bellIcon: bellIcon,
        }
    },
    props: {
        size: {
            type: Object,
            default: null
        },
        isWaitlist: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hints() {
            return this?.size?.hints || [];
        }
    }
}
