import { render, staticRenderFns } from "./subscribe.vue?vue&type=template&id=36605c12&scoped=true&"
import script from "./subscribe.vue?vue&type=script&lang=js&"
export * from "./subscribe.vue?vue&type=script&lang=js&"
import style0 from "./subscribe.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./subscribe.vue?vue&type=style&index=1&id=36605c12&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36605c12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonRow: require('/opt/atlassian/pipelines/agent/build/components/common/CommonRow.vue').default,CommonForm: require('/opt/atlassian/pipelines/agent/build/components/common/CommonForm.vue').default})
